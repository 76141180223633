html, body, #root {
  height: 100%;
}

body {
  background-position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100%;
}

body.light-mode {
  --outside-background: #ecedec;

  --black: #000;
  --gray-2: rgba(0, 0, 0, 0.55);
  --gray-1: rgba(0, 0, 0, 0.125);
  --white: #fff;

  --primary: hsl(11deg 98% 54%);
  --primary-bis: hsl(331deg 91% 49%);
}

body.dark-mode {
  --outside-background: #000;

  --black: #fff;
  --gray-2: rgba(255, 255, 255, 0.5);
  --gray-1: rgba(255, 255, 255, 0.25);
  --white: #1c1c1e;

  --primary: hsl(11deg 98% 54%);
  /* --primary: hsl(11deg 98% 43%); */
  --primary-bis: hsl(331deg 91% 39%);
}

body {
  color: var(--black);
  background: var(--outside-background);
}

@media (min-width: 701px) {
  body {
    padding-top: 30px;
  }
  .md-shadow-sm {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 6px;
  }
}

button:focus {
  outline: none;
}

.spin {
  animation: 2s linear spin infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  border-radius: 0;
}
